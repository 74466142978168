import React, { useState } from "react"
import Layout from "../components/Layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  EffectFade,
  Navigation,
  Keyboard,
  HashNavigation,
  History,
} from "swiper"
import SliderNavigation from "../components/SliderNavigation"
import "swiper/swiper-bundle.css"
import "../components/slider.css"
SwiperCore.use([EffectFade, Navigation, Keyboard, HashNavigation, History])

export default function SeriesPage({ data, location }) {
  const [controlledSwiper, setControlledSwiper] = useState(null)

  const { media } = data
  // const { title } = page.frontmatter
  const path = location.pathname.split("/")
  const slug = path[2]
  const imageIndex = path[3] ? path[3] : 0

  const images = media.edges
    .map(({ node }) => node.frontmatter)
    .filter(({ series }) => series.includes(slug))
    .map(({ image }) => ({
      image: image.childImageSharp.fluid,
    }))

  return (
    <Layout series={slug} subTitle={slug} image={images[imageIndex].image.src}>
      <SliderNavigation
        onNext={e => controlledSwiper.navigation.onNextClick(e)}
        onPrev={e => controlledSwiper.navigation.onPrevClick(e)}
      />
      <Swiper
        className={"hidden tablet:block"}
        keyboard
        loop={true}
        effect="fade"
        fadeEffect={{
          crossFade: true,
        }}
        speed={600}
        slidesPerView={1}
        onSwiper={setControlledSwiper}
        history={{
          replaceState: false,
          key: slug,
        }}
      >
        {images.map(({ image }, index) => (
          <SwiperSlide key={index} data-history={index}>
            <Img
              fluid={{
                ...image,
              }}
              imgStyle={{ objectFit: "contain" }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className={"tablet:hidden"}>
        {images.map(({ image }, index) => (
          <Img
            key={index}
            className={"m-5"}
            fluid={{
              ...image,
            }}
            imgStyle={{ objectFit: "contain" }}
          />
        ))}
      </div>
    </Layout>
  )
}

export const seriesPageQuery = graphql`
  query SeriesPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        series
      }
    }

    media: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "media" } } }
    ) {
      edges {
        node {
          frontmatter {
            series
            image {
              childImageSharp {
                fluid(
                  maxWidth: 1920
                  traceSVG: {
                    color: "lightgray"
                    optTolerance: 0.4
                    turdSize: 100
                    alphaMax: 0.5
                    turnPolicy: TURNPOLICY_MINORITY
                  }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`
